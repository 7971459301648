import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import styles from './timeWheel.module.sass';

const HOURS = Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, '0'));
const MINUTES = Array.from({ length: 12 }, (_, index) => (index * 5).toString().padStart(2, '0'));

interface ITimeWheelProps {
  onTimeChange: (time: string) => void;
  selectedTime: string;
  selectedDate: Date;
  minTime?: string;
  maxTime?: string;
  isDisabled?: boolean;
}

const TimeWheel = ({
  onTimeChange,
  selectedTime,
  selectedDate,
  minTime,
  maxTime,
  isDisabled = false,
}: ITimeWheelProps) => {
  const [selectedHour, setSelectedHour] = useState(selectedTime.split(':')[0]);
  const [selectedMinute, setSelectedMinute] = useState(selectedTime.split(':')[1]);
  const [isOpen, setIsOpen] = useState(false);

  const hoursRef = useRef<HTMLDivElement | null>(null);
  const minutesRef = useRef<HTMLDivElement | null>(null);

  const now = new Date();
  const isToday = selectedDate.toDateString() === now.toDateString();
  const currentHour = now.getHours().toString().padStart(2, '0');
  const currentMinute = Math.floor(now.getMinutes() / 5) * 5;

  const timeToMinutes = (time: string) => {
    const [hour, minute] = time.split(':').map(Number);
    return hour * 60 + minute;
  };

  const isTimeDisabled = (hour: string, minute: string) => {
    const timeInMinutes = timeToMinutes(`${hour}:${minute}`);
    const minLimit = minTime ? timeToMinutes(minTime) : 0;
    const maxLimit = maxTime ? timeToMinutes(maxTime) : 24 * 60;

    // Проверяем, если это текущий день
    if (isToday) {
      const currentTimeInMinutes = timeToMinutes(`${currentHour}:${currentMinute}`);
      if (timeInMinutes < currentTimeInMinutes) {
        return true;
      }
    }

    // Для всех дней проверяем minTime и maxTime
    return timeInMinutes < minLimit || timeInMinutes > maxLimit;
  };

  const isHourSelectable = (hour: string) => {
    const minLimit = minTime ? timeToMinutes(minTime) : 0;

    // Конец текущего часа
    const endOfHour = timeToMinutes(`${hour}:55`);

    // Проверяем, есть ли хотя бы одна доступная минута в часе
    return (
      endOfHour >= minLimit &&
      MINUTES.some((minute) => !isTimeDisabled(hour, minute))
    );
  };

  const getNearestValidMinute = (hour: string) => {
    const minLimit = minTime ? timeToMinutes(minTime) : 0;

    return (
      MINUTES.find((minute) => timeToMinutes(`${hour}:${minute}`) >= minLimit) ||
      MINUTES[MINUTES.length - 1]
    );
  };


  const handleSelectHour = (hour: string) => {
    if (!isHourSelectable(hour)) return;

    let adjustedMinute = selectedMinute;

    // Если выбранная минута недоступна для нового часа, выбираем ближайшую доступную минуту
    if (isTimeDisabled(hour, adjustedMinute)) {
      adjustedMinute = getNearestValidMinute(hour);
    }

    setSelectedHour(hour);
    setSelectedMinute(adjustedMinute);

    const newTime = `${hour}:${adjustedMinute}`;
    onTimeChange(newTime);
  };


  const handleSelectMinute = (minute: string) => {
    const newTime = `${selectedHour}:${minute}`;
    if (!isTimeDisabled(selectedHour, minute)) {
      setSelectedMinute(minute);
      onTimeChange(newTime);
    }
  };

  const scrollToValue = (ref: React.RefObject<HTMLDivElement>, value: string, list: string[]) => {
    if (ref.current) {
      const index = list.indexOf(value);
      const itemHeight = 35;
      ref.current.scrollTo({
        top: index * itemHeight,
        behavior: 'smooth',
      });
    }
  };

  const toggleDropdown = () => {
    if (!isDisabled) {
      setIsOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isOpen) {
      scrollToValue(hoursRef, selectedHour, HOURS);
      scrollToValue(minutesRef, selectedMinute, MINUTES);
    }
  }, [isOpen, selectedMinute, selectedHour]);

  useEffect(() => {
    const [hour, minute] = selectedTime.split(':');
    setSelectedHour(hour);
    setSelectedMinute(minute);
  }, [selectedTime]);

  return (
    <div className={styles.timeWheel}>
      <div
        className={classNames(styles.selectedTime, { [styles.disabled]: isDisabled })}
        onClick={toggleDropdown}
      >
        {selectedHour}:{selectedMinute}
      </div>
      {isOpen && (
        <div className={styles.timeWheelLists}>
          <div className={styles.wheelColumn} ref={hoursRef}>
            {HOURS.map((hour) => (
              <div
                key={hour}
                className={classNames(styles.wheelItem, {
                  [styles.selected]: hour === selectedHour,
                  [styles.wheelItemDisabled]: !isHourSelectable(hour),
                })}
                onClick={() => handleSelectHour(hour)}
              >
                {hour}
              </div>
            ))}
          </div>
          <div className={styles.wheelColumn} ref={minutesRef}>
            {MINUTES.map((minute) => (
              <div
                key={minute}
                className={classNames(styles.wheelItem, {
                  [styles.selected]: minute === selectedMinute,
                  [styles.wheelItemDisabled]: isTimeDisabled(selectedHour, minute),
                })}
                onClick={() => handleSelectMinute(minute)}
              >
                {minute}
              </div>
            ))}
          </div>
        </div>
      )}
      {isOpen && <div className={styles.overlay} onClick={toggleDropdown} />}
    </div>
  );
};

export default TimeWheel;
