import { useEffect, useRef, useState } from "react";
import Calendar from "@event-calendar/core";
import ListPlugin from "@event-calendar/list";
import TimePlugin from "@event-calendar/time-grid";
import SwitchSelector from "react-switch-selector";
import "@event-calendar/core/index.css";
import "./eventCalendar.sass";
import { useAppSelector } from "../../../../store/index";
import { tasksSelector } from "../../../../features/index";
import SettingsTask from "./SettingsTask/SettingsTask";
import { TTask } from "types/types";

type TView = "timeGridDay" | "listWeek";

const options = [
  { label: "День", value: "timeGridDay" },
  { label: "Неделя", value: "listWeek" },
];

const defaultPosition = { left: 0, top: 0 };

// Иконки для кнопки переключения
const expandIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z"/>
  </svg>
`;

const collapseIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z"/>
  </svg>
`;

const EventCalendar = () => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const [currentView, setCurrentView] = useState<TView>("timeGridDay");
  const { result: tasks } = useAppSelector(tasksSelector);
  const [activeTask, setActiveTask] = useState<TTask | null>(null);
  const [settingsPosition, setSettingsPosition] = useState(defaultPosition);

  const calendarInstance = useRef<Calendar | null>(null);

  useEffect(() => {
    document.body.style.overflow = settingsPosition.left ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [settingsPosition.left]);

  useEffect(() => {
    if (calendarRef.current && !calendarInstance.current) {
      const now = new Date();
      const startHour = `${String(now.getHours()).padStart(2, "0")}:00`;

      calendarInstance.current = new Calendar({
        target: calendarRef.current,
        props: {
          plugins: [ListPlugin, TimePlugin],
          options: {
            events: tasks
              ? tasks.map((task, index) => ({
                  title: {
                    html: `
                      <div class='taskInfo ${task.isOpen ? "" : "taskClosed"}' key=${task.id} id=${task.id}>
                        ${task.taskName}
                        ${!!task.comment ? `<p class='taskComment'>${task.comment}</p>` : ""}
                      </div>
                    `,
                  },
                  start: new Date(task.dateStart),
                  end: new Date(task.dateEnd),
                  id: index,
                  className: !task.isOpen ? "closeEvent" : ""
                }))
              : [],
            view: currentView,
            slotMinTime: startHour,
            eventClick: (info) => {
              if (tasks) {
                setActiveTask(tasks[+info.event.id])
                //@ts-ignore
                setSettingsPosition({ left: currentView === "timeGridDay" ?  82 : 30, top: info.jsEvent.pageY + 25 })
              }
            },
            locale: 'ru',
            buttonText: {
              today: "Сегодня",
            },
            slotDuration: '00:15',
            noEventsContent: "Нет задач",
            slotHeight: 70,
            nowIndicator: true,
            datesSet: (info) => {
              const activeDate = new Date(info.start).toDateString();
              const isToday = activeDate === new Date().toDateString();

              calendarInstance.current?.setOption(
                "slotMinTime",
                isToday ? startHour : "00:00"
              );

              const buttonContainer =
                calendarRef.current?.querySelector(".fullDayToggleContainer");
              if (buttonContainer) buttonContainer.remove();

              if (isToday && currentView === "timeGridDay" && calendarRef.current) {
                const header = calendarRef.current.querySelector(".ec-header");
                if (header) {
                  const buttonContainer = document.createElement("div");
                  buttonContainer.className = "fullDayToggleContainer";
                  buttonContainer.innerHTML = `<button class="toggleButton">${expandIcon}</button>`;
                  header.after(buttonContainer);

                  const toggleButton = buttonContainer.querySelector(".toggleButton");
                  toggleButton?.addEventListener("click", () => {
                    const currentSlotMinTime = calendarInstance.current?.getOption(
                      "slotMinTime"
                    );

                    calendarInstance.current?.setOption(
                      "slotMinTime",
                      //@ts-ignore
                      currentSlotMinTime.seconds === 0 ? startHour : "00:00"
                    );
                    // TODO Проверить, если что убрать
                    //@ts-ignore
                    toggleButton.style.opacity =  currentSlotMinTime.seconds === 0 ? 1 : 0.7
                    toggleButton.innerHTML =
                       //@ts-ignore
                       currentSlotMinTime.seconds === 0 ? expandIcon : collapseIcon;
                  });
                }
              }
            },
          },
        },
      });
    }

    return () => {
      calendarInstance.current?.destroy();
      calendarInstance.current = null;
    };
  }, [currentView, tasks]);

  const onChange = (newValue: TView) => {
    setCurrentView(newValue);
  };

  return (
    <div>
      <div className={"switchContainer"}>
        <SwitchSelector
          onChange={(value) => onChange(value as TView)}
          options={options}
          backgroundColor={window.Telegram.WebApp.themeParams?.section_separator_color}
          fontColor={"#333"}
          selectedBackgroundColor={window.Telegram.WebApp.themeParams?.button_color}
          selectedFontColor={window.Telegram.WebApp.themeParams?.button_text_color}
          wrapperBorderRadius={6}
          optionBorderRadius={6}
          selectionIndicatorMargin={2}
        />
      </div>
      <div ref={calendarRef} />
      {!!settingsPosition.left && (
        <SettingsTask
          position={settingsPosition}
          resetPosition={() => setSettingsPosition(defaultPosition)}
          activeTask={activeTask}
        />
      )}
    </div>
  );
};

export default EventCalendar;
