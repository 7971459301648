import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import styles from "./calendar.module.sass"
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale";
import { TimeWheel } from "./TimeWheel";
import { Label } from "../Label";
import { isToday } from "date-fns";
registerLocale("el", ru);

interface ICalendarProps {
  onChange: (dates: Date[]) => void;
  values: Date[];
  isDisabled?: boolean;
  name?: string;
}

const roundToNextMultipleOfFive = (num: number): string => {
  // Округление до ближайшего большего кратного 5
  let rounded = Math.ceil(num / 5) * 5;

  // Если значение больше или равно 60, возвращаем "00"
  if (rounded >= 60) {
    return "00";
  }

  // Возвращаем строку с добавлением нуля, если значение меньше 10
  return rounded.toString().padStart(2, '0');
};

const getNextHour = (time: string) => {
  const arrTime = time.split(":")
  const nextHour = +arrTime[0] + 1
  return `${nextHour > 23 ? arrTime[0] : nextHour}:${arrTime[1]}`
}

const Calendar = ({ onChange, values, isDisabled, name }: ICalendarProps) => {
  const [dateStartValue, dateEndValue] = values;
  const [date, setDate] = useState<Date>(dateStartValue ?? new Date());
  const [startTime, setStartTime] = useState<string>(`
    ${new Date(date).getHours()}:${roundToNextMultipleOfFive(new Date(date).getMinutes())}`
  );

  const [endTime, setEndTime] = useState<string>(
    `${dateEndValue.getHours()}:${roundToNextMultipleOfFive(dateEndValue.getMinutes())}`
  );
  const [isCustomTimeStart, setCustomTimeStart] = useState<boolean>(false);
  const [isCustomTimeEnd, setCustomTimeEnd] = useState<boolean>(false);

  const updateDateWithTimes = (selectedDate: Date | null, startTime: string, endTime: string) => {
    if (!selectedDate) return;

    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    const startDate = new Date(selectedDate);
    startDate.setHours(startHours, startMinutes, 0);

    const endDate = new Date(selectedDate);
    endDate.setHours(endHours, endMinutes, 0);

    onChange([startDate, endDate]);
  };

  useEffect(() => {
    updateDateWithTimes(date, startTime, endTime)

  }, [date, startTime, endTime])

  const validTime = (selectedDate: Date) => {
    const currentHours = new Date(selectedDate).getHours()
    const currentMinutes = new Date(selectedDate).getMinutes()
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    return (currentHours > startHours || currentHours > endHours || currentMinutes > startMinutes || currentMinutes > endMinutes) && isToday(selectedDate)
  }

  const handleDateChange = (selectedDate: Date) => {
    setDate(selectedDate);

    if ((isCustomTimeStart || isCustomTimeEnd) && !validTime(selectedDate)) {
      return
    }
    if (!isToday(selectedDate)) {
      setStartTime("08:00")
      setEndTime("09:00")
    } else {
      const newTimeStart = `${new Date(selectedDate).getHours()}:${roundToNextMultipleOfFive(new Date(selectedDate).getMinutes())}`
      setStartTime(newTimeStart)
      setEndTime(getNextHour(newTimeStart))
    }
  };

  const onTimeStartChange = (hours: string, minutes: string) => {
    const newTimeStart = `${hours}:${minutes}`
    const [endHours, endMinutes] = endTime.split(':')
    setStartTime(newTimeStart)
    setCustomTimeStart(true)
    if (!isCustomTimeEnd || (hours > endHours || minutes > endMinutes)) {
      setEndTime(getNextHour(newTimeStart))
    }
  };

  const onTimeEndChange = (hours: string, minutes: string) => {
    setEndTime(`${hours}:${minutes}`)
    setCustomTimeEnd(true);
    const startTimeArr = startTime.split(":")

    if (+startTimeArr[0] > +hours) {
      const prevHours = +hours - 1;
      setStartTime(`${(prevHours < 0 ? "00" : prevHours <= new Date().getHours() ? hours : prevHours).toString().padStart(2, '0')}:00`)
    }
    if (+startTimeArr[1] > +minutes) {
      setStartTime(`${startTimeArr[0]}:00`)
    }
  };

  return (
    <>
      <DatePicker
        selected={date}
        onChange={(date) => {
          handleDateChange(date ? date : new Date())
        }}
        dateFormat="dd.MM.yyyy"
        className={styles.datePicker}
        calendarClassName={styles.calendar}
        popperClassName={styles.popper}
        locale="el"
        disabled={isDisabled}
        minDate={new Date()}
        name={name}
      />
      <div className={styles.time}>
        <Label labelText="Начало" isRequired>
          {/* <TimeWheel onTimeChange={setStartTime} selectedDate={date} time={startTime} isDisabled={isDisabled} /> */}
          <TimeWheel
        onTimeChange={(value) => {
          const [hours, minutes ] = value.split(":").map(Number)
          const [endHours, endMinutes] = endTime.split(":").map(Number);

          if (endHours < hours || endMinutes < minutes) {
            setEndTime(value)
          }

          setStartTime(value)
        }}
        selectedTime={startTime.trim()}
        selectedDate={date}
        // maxTime={endTime} // Limit end time
      />
        </Label>
        <Label labelText="Конец" isRequired>
      <TimeWheel
        onTimeChange={setEndTime}
        selectedTime={endTime.trim()}
        selectedDate={date}
        minTime={startTime} // Limit start time
      />
          {/* <TimeWheel onTimeChange={setEndTime} selectedDate={date} time={endTime} isDisabled={isDisabled} limitation={startTime} /> */}
        </Label>
      </div>
    </>
  )
}

export default Calendar;