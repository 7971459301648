import { addSnack, createTask, createTaskSelector, editTaskSelector, resetCreateTask, tasksSelector, toggleCreateTaskModal, toggleEditTaskModal, updateTask, updateTasks, updateTaskSelector } from "../../../../features/index";
import { Form, Formik } from "formik";
import { useEffect, useId, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/index";
import { Button, Calendar, Input, Label, Select, Switch, TextArea } from "../../../elements";
import { initialValuesForm, reminderTimeOptions, validationSchema } from "./helpers";
import styles from "./taskCreationForm.module.sass";

const TaskCreationForm = () => {
  const dispatch = useAppDispatch();
  const snackId = useId();
  const [isNotification, setNotification] = useState(false);
  const [timeNotification, setTimeNotification] = useState<string | number>(0);
  console.log(timeNotification);

  const [filteredReminderOptions, setFilteredReminderOptions] = useState(reminderTimeOptions); // Отфильтрованные варианты
  const { fetching, success, message } = useAppSelector(createTaskSelector);
  const { fetching: updateTaskFetching, success: updateTaskSuccess, message: updateTaskMessage } = useAppSelector(updateTaskSelector);
  const editTaskInfo = useAppSelector(editTaskSelector);
  const { result } = useAppSelector(tasksSelector);

  useEffect(() => {
    if (!fetching && success) {
      dispatch(addSnack({
        variant: "success",
        text: "Задача создана",
        id: snackId,
      }));
      dispatch(toggleCreateTaskModal());
      dispatch(toggleEditTaskModal(null))
    }
    if (!fetching && !success && message) {
      dispatch(addSnack({
        variant: "error",
        text: message,
        id: snackId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, success]);

  useEffect(() => {
    if (!updateTaskFetching && updateTaskSuccess) {
      dispatch(addSnack({
        variant: "success",
        text: "Задача изменена",
        id: snackId,
      }));
      dispatch(toggleCreateTaskModal());
      dispatch(toggleEditTaskModal(null))
    }
    if (!updateTaskFetching && !updateTaskSuccess && updateTaskMessage) {
      dispatch(addSnack({
        variant: "error",
        text: message,
        id: snackId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskFetching, updateTaskSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateTask());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterReminderOptions = (dateStart: Date) => {
    const now = new Date().getTime();
    const timeDifference = dateStart.getTime() - now;

    // Фильтруем варианты напоминаний, чтобы показывать только те, которые еще могут сработать
    const filteredOptions = reminderTimeOptions.filter(option => option.value <= timeDifference);

    setFilteredReminderOptions(filteredOptions);

    if (filteredOptions.length === 0 ||
      filteredOptions.findIndex((option) => option.value === timeNotification) === -1) {
      setNotification(false);
    }
  };

  useEffect(() => {
    if (editTaskInfo && editTaskInfo.reminderTime) {
      setNotification(true);
    }
  }, [editTaskInfo])

  return (
    <Formik
      initialValues={editTaskInfo ? {
        taskName: editTaskInfo.taskName,
        dateStart: new Date(editTaskInfo.dateStart),
        dateEnd: new Date(editTaskInfo.dateEnd),
        comment: editTaskInfo.comment,
        reminderTime: editTaskInfo.reminderTime ?? 0,
      }
        : initialValuesForm}
      validationSchema={validationSchema}
      onSubmit={async ({ dateStart, dateEnd, reminderTime, comment, taskName }) => {
        const body = {
          taskName,
          ...(editTaskInfo ? { id: editTaskInfo.id } : {}),
          dateStart: dateStart.getTime(),
          dateEnd: dateEnd.getTime(),
          ...comment ? { comment } : {},
          ...isNotification && reminderTime ? { reminderTime: dateStart.getTime() - reminderTime } : {},
        };
        if (!editTaskInfo) {
          dispatch(createTask(body));
        } else {
          dispatch(updateTask(body))

          dispatch(updateTasks(result?.map((task) => task.id === body?.id ? { ...editTaskInfo, ...body } : task)))
        }
      }}
    >
      {(props) => {
        return (
          <Form className={styles.form}>
            <div className={styles.inputs}>
              <Label labelText="Название задачи" isRequired name={"taskName"}>
                <Input name="taskName" disabled={fetching} id="taskName" />
              </Label>
              <Label labelText="Дата" isRequired name={'date'}>
                <Calendar
                  onChange={(dates) => {
                    props.setFieldValue("dateStart", dates[0]);
                    props.setFieldValue("dateEnd", dates[1]);
                    filterReminderOptions(dates[0]);
                  }}
                  values={[props.values.dateStart, props.values.dateEnd]}
                  isDisabled={fetching}
                  name={'date'}
                />
              </Label>
              <Label labelText="Описание задачи" name="comment">
                <TextArea name="comment" disabled={fetching} id='comment' />
              </Label>
              {filteredReminderOptions.length ? <div className={styles.notificationControl}>
                <Switch
                  isDisabled={fetching || !filteredReminderOptions.length}
                  label="Напомнить о задаче"
                  id="notification"
                  onChange={() => {
                    setNotification(!isNotification)
                    props.setFieldValue("reminderTime", filteredReminderOptions.length ? filteredReminderOptions[0].value : 0)
                    setTimeNotification(filteredReminderOptions.length ? filteredReminderOptions[0].value : 0)
                  }}
                  isChecked={isNotification}
                  name="notification"
                />
                {isNotification && filteredReminderOptions.length > 0 && (
                  <div className={styles.reminderTime}>
                    за:
                    <Select
                      value={reminderTimeOptions.find(({ value }) => value === props.values.reminderTime)?.label || ""}
                      options={filteredReminderOptions}
                      onChange={(value) => {
                        props.setFieldValue("reminderTime", value)
                        setTimeNotification(value)
                      }}
                      classNameContainer={styles.select}
                      isDisabled={fetching}
                    />
                  </div>
                )}
              </div>
                :
                <span className={styles.textNotTime}>Нет доступного времени для напоминания</span>
              }
            </div>
            <Button type="submit" content={fetching ? editTaskInfo ? "Сохранение" : "Создание" : editTaskInfo ? "Сохранить" : "Создать задачу"} additionalClassName={styles.createButton} disabled={fetching} />
          </Form>
        );
      }}
    </Formik>
  );
}

export default TaskCreationForm;
